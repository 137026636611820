@import '../../variables.scss';

.container {
  position: absolute; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: $spacing;
}

.header {
  text-align: center;
  height: 50px;
  background-color: dodgerblue;

  .text {
    color: rgb(224, 224, 224);
    font-weight: 600;
    line-height: 50px;
  }
}

.content {
  height: 300px;

  .login-with {
    position: absolute; /* or absolute */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text {
      text-align: center;
    }
    .image {
      max-width: 200px;
      max-height: 200px;
      width: 200px;
      background-color: orangered;
    }
  }
}
