@import '../../variables.scss';

.container {
  margin-top: $spacing;
  padding: $spacing;
}

.text {
  width: 50%;
  text-align: left;
  display: inline-block;
}

.logout {
  display: inline-block;
  position: relative;
  text-align: right;
  width: 50%;

  .button {
    color: white;
  }
}
